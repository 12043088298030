import React from "react"
import { Row, Col } from "react-bootstrap"
import CardLeistung from "./cardLeistung"
import "../styles/leistungen.css"

import thumbSteildach from "../assets/leistungen/list/thumbSteildach.jpg"
import thumbFlachdach from "../assets/leistungen/list/thumbFlachdach.jpg"
import thumbSanierung from "../assets/leistungen/list/thumbSanierung.jpg"
import thumbKupfer from "../assets/leistungen/list/thumbKupfer.jpg"
import thumbHolz from "../assets/leistungen/list/thumbHolz.jpg"
import thumbBalkon from "../assets/leistungen/list/thumbBalkon.jpg"
import thumbFenster from "../assets/leistungen/list/thumbDachfenster.jpg"
import thumbSolar from "../assets/leistungen/list/thumbSolar.jpg"
import thumbGruen from "../assets/leistungen/list/thumbGruen.jpg"
import thumbDaemmung from "../assets/leistungen/list/thumbDaemmung.jpg"
import thumbWartung from "../assets/leistungen/list/thumbWartung.jpg"
import thumbKran from "../assets/leistungen/list/thumbKran.jpg"
import thumbSchmuck from "../assets/leistungen/list/thumbSchmuck.jpg"
import thumbFassaden from "../assets/leistungen/list/thumbFassaden.jpg"
import thumbAbdichtung from "../assets/leistungen/list/thumbAbdichtung.jpg"
import thumbTerrassendach from "../assets/leistungen/list/thumbTerrassendach.jpg"

export default () => (
  <div>
    <Row className="show-grid text-center">
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbSteildach}
          cardTitle="Bedachung Steildach"
          cardLink="/leistungen/steildach"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbFlachdach}
          cardTitle="Bedachung Flachdach"
          cardLink="/leistungen/flachdach"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbSanierung}
          cardTitle="Sanierung &amp; Neueindeckung"
          cardLink="/leistungen/sanierung"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbFassaden}
          cardTitle="Fassaden"
          cardLink="/leistungen/fassaden"
        />
      </Col>
    </Row>
    <Row className="show-grid text-center">
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbAbdichtung}
          cardTitle="Bauwerks-abdichtung"
          cardLink="/leistungen/abdichtung"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbBalkon}
          cardTitle="Terasse &amp; Balkon"
          cardLink="/leistungen/balkon"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbTerrassendach}
          cardTitle="Überdachung Terrasse"
          cardLink="/leistungen/terrassendach"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbFenster}
          cardTitle="Dachfenster von VELUX &amp; ROTO"
          cardLink="/leistungen/dachfenster"
        />
      </Col>
    </Row>
    <Row className="show-grid text-center">
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbSolar}
          cardTitle="Solarthermie &amp; Photovoltaik"
          cardLink="/leistungen/solar"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbGruen}
          cardTitle="Dachbegrünung"
          cardLink="/leistungen/dachbegruenung"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbDaemmung}
          cardTitle="Wärmedämmung"
          cardLink="/leistungen/waerme"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbHolz}
          cardTitle="Holz- &amp; Zimmerarbeiten"
          cardLink="/leistungen/holz"
        />
      </Col>
    </Row>
    <Row className="show-grid text-center">
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbKupfer}
          cardTitle="Kupfer- &amp; Zinkarbeiten"
          cardLink="/leistungen/kupferzink"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbSchmuck}
          cardTitle="Dachschmuck"
          cardLink="/leistungen/dachschmuck"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbWartung}
          cardTitle="Reparatur &amp; Wartung"
          cardLink="/leistungen/wartung"
        />
      </Col>
      <Col xs={6} lg={true}>
        <CardLeistung
          thumbImageSrc={thumbKran}
          cardTitle="Kranverleih"
          cardLink="/kranverleih"
        />
      </Col>
    </Row>
  </div>
)
