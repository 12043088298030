import React from "react"
import { Container } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselLeistungen"
import ContactBox from "../components/boxContact"
import ListeLeistungen from "../components/listLeistungen"
import ListeZertifikate from "../components/listZertifikate"
import ConditionsBox from "../components/boxKonditionen"

export default () => (
  <div>
    <Header />
    <Carousel />
    <div className="contentbox gray">
      <Container className="text-center">
        <h1>Unsere Leistungen</h1>
        <hr />
      </Container>
    </div>
    <div className="arrow-up white" />
    <div className="contentbox white">
      <Container>
        <ListeLeistungen />
      </Container>
    </div>
    <ConditionsBox />
    <ContactBox />
    <div className="arrow-up gray" />
    <div className="contentbox gray foot">
      <ListeZertifikate />
    </div>
    <Footer />
  </div>
)
