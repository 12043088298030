import React from "react"
import { Button, Card } from "react-bootstrap"
import { Link } from "gatsby"
import Bounce from "react-reveal/Bounce"
import "../styles/leistungen.css"

export default props => (
  <Bounce>
    <Card className="leistungen-card">
      <Card.Img variant="top" src={props.thumbImageSrc} />
      <Card.ImgOverlay>
        <Card.Text>
          <h5>{props.cardTitle}</h5>
        </Card.Text>
        <Card.Text>
          <Link to={props.cardLink}>
            <Button variant="danger" className="leistungen-button">
              Mehr erfahren
            </Button>
          </Link>
        </Card.Text>
      </Card.ImgOverlay>
    </Card>
  </Bounce>
)
