import React from "react"
import { Container } from "react-bootstrap"
import "../styles/jobs.css"

export default props => (
  <div>
    <div className="arrow-up gray" />
    <div className="contentbox gray">
      <Container className="text-center">
        <h2>Unsere Konditionen</h2>
        <hr />
        <p />
        <div className="text-left">
          Bei Abrechnung nach Arbeitswerten entspricht 1 Arbeitswert = 0,25 Std.
          <br />
          Arbeiten unter 1 Std. werden mit mind. 4 Arbeitswerten pro Handwerker
          berechnet.
          <br />
          Für Arbeiten unter 3 Std. pro Handwerker wird die Fahrtzeit neben
          einer Anfahrtspauschale als Arbeitswert berechnet.
          <br />
          Liegt der Auftragsort mehr als 25km von der Betriebsstätte des
          Auftragnehmers entfernt wird die Fahrtzeit neben einer
          Anfahrtspauschale als Arbeitswert berechnet, unabhängig von der Dauer
          der Arbeiten.
          <br />
          Materialien, Entsorgungskosten, Arbeitsschutzmaßnahmen,
          Erschwerniszulagen und Kraneinsätze, etc. werden individuell
          berechnet.
        </div>
      </Container>
    </div>
  </div>
)
